import * as React from 'react';
import loadable from '@loadable/component';
import { Header } from 'mycomponents/Header';
import { CopyrightFooter } from 'mycomponents/CopyrightFooter';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import './App.less';
import { resource } from './utils';
import { LanguageProvider } from 'mycomponents/LanguageContainer';
import { SuiWallet, EthosWallet, MartianWallet, SuietWallet, SurfWallet, OneKeyWallet, SpacecyWallet } from '@suiet/wallet-kit';
// eslint-disable-next-line
import { WalletProvider, ConnectButton, useAccountBalance, useWallet, useCoinBalance, useChain, SuiChainId, ErrorCode } from '@suiet/wallet-kit';
import '@suiet/wallet-kit/style.css';
import styled from 'styled-components';

function createLoadable(thunk: () => Promise<React.FC | { default: React.FC }>) {
  const LoadableComponent = loadable(() => thunk(), {
    fallback: (
      <LoaderWrapper className="h-screen flex justify-center items-center text-white py-20">
        <img className="block absolute top-[25%] z-10 md:w-[30rem] w-[20rem] object-center" src={resource('home/logo.svg')} alt="Loading" />
      </LoaderWrapper>
    ),
  });
  return <LoadableComponent />;
}

const LoaderWrapper = styled.div`
  img {
    display: block;
    margin: 1rem auto;
    animation: loading 10s linear infinite;

    @keyframes loading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
        <WalletProvider
          defaultWallets={[
            // order defined by you
            SuiWallet,
            EthosWallet,
            MartianWallet,
            SuietWallet,
            SurfWallet,
            OneKeyWallet,
            SpacecyWallet,
            // ...
          ]}
        >
          <div className="App">
            <Header />
            <main>
              <Routes>
                <Route path="/" element={createLoadable(() => import('./views/Home'))} />
                <Route path="/exchange/*" element={createLoadable(() => import('./views/Swap'))} />
                <Route path="/lp/*" element={createLoadable(() => import('./views/Liquidity'))} />
                <Route path="/pools/*" element={createLoadable(() => import('./views/Pools'))} />
                <Route path="/nftamm/*" element={createLoadable(() => import('./views/NFT'))} />
              </Routes>
              <ToastContainer />
            </main>
            <CopyrightFooter/>
          </div>
        </WalletProvider>
      </LanguageProvider>
    </QueryClientProvider>
  );
}

export default App;
