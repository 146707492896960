import { formatUnits } from 'ethers';

export function truncateMiddle(str: string, maxLength = 10): string {
  if (str.length <= maxLength) {
    return str;
  }

  const half = Math.floor(maxLength / 2);
  return str.substr(0, half) + '...' + str.substr(str.length - half);
}

export function asserts(x: unknown, message = 'not valid'): asserts x {
  if (!x) throw new Error(message);
}

export function publicUrl(path: string): string {
  return `${process.env.PUBLIC_URL}/resource/${path}`;
}

export function isNonNil<T>(x: T): x is NonNullable<T> {
  return x !== undefined && x !== null;
}

export const toHuman = (amount: bigint): string => {
  return formatUnits(amount, 18);
};

export function resource(url: string) {
  return `${process.env.PUBLIC_URL}/assets/${url}`;
}

export function unimplemented(): never {
  throw new Error('unimplemented');
}

export function asyncSleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function fallbackCopyTextToClipboard(text: string): void {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    throw err;
  } finally {
    document.body.removeChild(textArea);
  }
}

export function copyTextToClipboard(text: string): Promise<void> | void {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  return navigator.clipboard.writeText(text);
}

export const clear0x = (address: string): string => {
  if (address.startsWith('0x')) {
    return address.slice(2);
  }
  return address;
};
