import * as React from 'react';
import { useSelectedRoute } from '../Header';
import { Text } from '../LanguageContainer';
import styled from 'styled-components';
import { resource } from '../../utils';

export const CopyrightFooter: React.FC = () => {
  let selected = useSelectedRoute();
  return (
    <FootContainer
      className={`${selected === 'home' ? 'hidden' : ''} flex flex-row justify-between align-center h-[6rem] leading-[6rem] px-[10rem] whitespace-nowrap`}
    >
      <Text tid="GiroSwap © 2023. All rights are reserved." />
      <div className="mt-[1rem] flex flex-row justify-between items-center z-30 md:w-[5rem] ml-[0.5rem]">
        <a className="mr-[1.5rem]" href="https://discord.gg/giroswap" target="_blank" rel="noreferrer">
          <img className="mx-auto h-[1.6rem] cursor-pointer" src={resource('home/discord.svg')} alt="discord" />
        </a>
        <a className="mr-[1.3rem]" href="https://twitter.com/giroswap" target="_blank" rel="noreferrer">
          <img className="mx-auto h-[1.4rem] cursor-pointer" src={resource('home/twitter.svg')} alt="whitepaper" />
        </a>
      </div>
    </FootContainer>
  );
};

const FootContainer = styled.div<{ className: string }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #000000;
`;
