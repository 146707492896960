import React from 'react';
import { resource } from '../../utils';
import styled from 'styled-components';

export const LogoHeader: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <TitleWrapper className={`${className ? className : ''} flex flex-row justify-center items-center cursor-pointer`}>
      <img className="w-8/12" src={resource('home/logo-title.svg')} alt="logo" />
      {/*<TitleText>GiroSwap</TitleText>*/}
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  color: #ffffff;
  font-family: 'IBMPlexSans-Medium', 'IBMPlexSans-Bold', serif;
  font-weight: 800;
  @media (max-width: 768px) {
  }
  img.logo {
    display: block;
    margin: 1rem auto;
    animation: loading 10s linear infinite;

    @keyframes loading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

// eslint-disable-next-line
const TitleText = styled.div`
  color: #ffffff;
  font-family: 'IBMPlexSans-Black', serif;
  font-style: normal;
  font-weight: 900;
  text-align: center;

  @media (max-width: 768px) {
  }
`;
