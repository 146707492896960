import * as React from 'react';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from 'mycomponents/LanguageContainer';
import { ConnectButton } from '@suiet/wallet-kit';
import { LogoHeader } from '../LogoHeader';

export const useSelectedRoute = () => {
  const { pathname } = useLocation();
  return useMemo(() => {
    const names = ['exchange', 'lp', 'ido', 'game', 'nft', 'pools'];
    for (const name of names) {
      if (pathname.includes(name)) {
        return name;
      }
    }
    return 'home';
  }, [pathname]);
}

export const Header: React.FC = () => {
  let selected = useSelectedRoute();

  return (
    <HeadContainer className={`${selected === 'home' ? 'hidden' : ''} flex items-center justify-between mx-auto px-4 md:px-[2.5%] h-[5.1rem]`}>
      <Link to="/">
        <LogoHeader className="md:w-[12rem] w-[10rem] md:text-2xl text-xl" />
      </Link>

      <TabsContainer className="flex justify-start items-center text-xl md:text-2xl overflow-hidden max-w-full md:w-5/12 w-0/12">
        <div className="tabs flex text-xl items-center md:text-2xl ml-2">
          <TextWrapper className={selected === 'exchange' || selected === 'lp' ? 'selected' : ''}>
            <Link to="/exchange" className="cursor-pointer">
              <Text tid="exchange" />
            </Link>
          </TextWrapper>

          <TextWrapper className={selected === 'pools' ? 'selected' : ''}>
            <Link to="/pools" className="cursor-pointer">
              <Text tid="Pools" />
            </Link>
          </TextWrapper>

          <TextWrapper className={selected === 'nft' ? 'selected' : ''}>
            {/*<Link to="nftamm" className="cursor-pointer">*/}
            <Link to="#" className="cursor-pointer">
              <Text tid="NFT AMM" />
            </Link>
          </TextWrapper>
        </div>
      </TabsContainer>

      <ConnectButtonWrapper className="flex justify-end items-center font-mono md:ml-[2rem] ml-[1rem]">
        <ConnectButton>Connect</ConnectButton>
      </ConnectButtonWrapper>
    </HeadContainer>
  );
};

// styled.button<{ borderColor: string }>
const HeadContainer = styled.div<{ hidden?: boolean }>`
  //display: ${(props) => (props.hidden ? 'none' : 'flex')} !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  // background: linear-gradient(90.18deg, rgba(0, 0, 0, 0.1) 60%, rgba(190, 190, 190, 0.2) 100%);
  // //background: #F8F9FA;
  // backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.1);

  font-family: 'IBMPlexSans', sans-serif;
  //color: #8A1538;
  //color: #f8f9fa;
`;

// eslint-disable-next-line
const BalanceWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: #ffffff;
  @media (max-width: 768px) {
    display: none;
  }
`;

// eslint-disable-next-line
const NetworkWrapper = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 1.2rem;
  line-height: 1.5rem;
  align-items: center;
  text-align: center;
  color: rgba(229, 221, 221, 0.76);
  height: 2.5rem;
  font-family: 'IBMPlexSans-SemiBold', serif;

  background: linear-gradient(91.68deg, #ff008a 0.64%, #0075ff 101.05%);
  border-radius: 33px;
  @media (max-width: 768px) {
    display: none;
  }
`;

// eslint-disable-next-line
const TextWrapper = styled.div`
  a {
    letter-spacing: 0.02em;
    display: inline-block;
    margin-left: 1rem;
    margin-right: 1rem;

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #F2F3FF;

    font-family: 'IBMPlexSans', 'IBMPlexSans-Regular', serif;
  }

  &.selected > a {
    font-weight: 600;
    //color: #ffa600;
    //text-shadow: 2px 4px 2px #62150c;

    background: linear-gradient(90deg, #2684C6 0%, #337CC1 15%, #DA1784 85%, #EB0C7E 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const ConnectButtonWrapper = styled.div`
  .wkit-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 19px 47px;
    gap: 10px;
    
    position: absolute;
    width: 156px;
    height: 57px;
    right: 2%;
    top: calc(50% - 57px/2 - 0.5px);
    
    /* Linear */
    
    background: linear-gradient(90deg, #2684C6 0%, #337CC1 15%, #DA1784 85%, #EB0C7E 100%);
    border-radius: 80px;
    @media (max-width: 768px) {
      width: 13.5rem;
      font-size: 0.9rem;
    }
  }

  .wkit-connected-container {
    font-weight: 900;

    width: 17rem;
    //background: #00000055;
    background: linear-gradient(12.18deg, #ff007a -1.5%, #0066ff 98.84%);

    border: 13px solid linear-gradient(92.18deg, #ff007a -1.5%, #0066ff 98.84%);
    border-image-source: linear-gradient(92.18deg, #ff007a -1.5%, #0066ff 98.84%);
    border-radius: 5px;

    color: #d9d9d9;

    @media (max-width: 768px) {
      width: 13.5rem;
      font-size: 10px;
    }

    .wkit-connected-button__divider {
      @media (max-width: 768px) {
        margin: 0 3px;
      }
    }

    .wkit-address-select__address {
      font-weight: 900;

      color: #d9d9d9;
      @media (max-width: 768px) {
        font-size: 0.8rem;
      }
    }

    .wkit-connected-button__balance {
      font-weight: 900;
      font-size: 1rem;
      color: #d9d9d9;
      @media (max-width: 768px) {
        font-size: 0.6rem;
      }
    }

    .wkit-disconnect-button__container {
      font-weight: 900;
      background: linear-gradient(92.18deg, #ff007a -1.5%, #0066ff 98.84%);
      @media (max-width: 768px) {
        font-size: 0.7rem;
      }
    }

    .wkit-disconnect-button {
      font-weight: 900;
      color: #d9d9d9;
      @media (max-width: 768px) {
        font-size: 0.7rem;
      }
    }
  }
`;

const TabsContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
